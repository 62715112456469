@tailwind utilities;
@tailwind base;

@layer base {
  * {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;

    &:focus {
      outline: none !important;
    }
  }

  html,
  body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  html {
    font-size: 16px;
  }

  body {
    font-size: 0.875rem;
  }

  body {
    font-feature-settings: 'salt';
  }

  hr {
    margin: 32px 0;
    border-bottom-width: 1px;
  }

  img {
    width: 100%;
    vertical-align: top;
  }

  input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentColor;
  }

  body, .dark, .light {
    @apply text-default bg-default #{'!important'};
  }

  *, *::before, *::after {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--app-border-rgb), var(--tw-border-opacity));

    .dark & {
      --tw-border-opacity: 0.12 !important;
    }
  }

  [disabled] * {
    @apply text-disabled #{'!important'};
  }

  @media print {
    html {
      font-size: 12px !important;
    }

    body, .dark, .light {
      background: none !important;
    }
  }
}

@tailwind components;
