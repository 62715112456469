@import 'components/input';
@import 'components/spinner';

@import 'overrides/angular-material';
@import 'overrides/highlightjs';
@import 'overrides/perfect-scrollbar';
@import 'overrides/quill';

.icon-warn {
  svg {
    @apply fill-red-400;
  }
}

.icon-success {
  svg {
    @apply fill-green-400;
  }
}
